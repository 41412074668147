import { Button } from 'antd';
import i18n from 'i18n';
import { OmsOrderDetail } from 'types/oms';
import { CURRENCY_MAP } from 'commons/options';
import printOrderReceipt from 'utils/print-order-receipt';
import connectToPosPrinter from 'utils/connect-to-pos-printer';
import usePosPrinter from 'commons/hooks/use-pos-printer';

const PrintReceiptButton = ({
  omsOrderDetail,
  onPrintReceiptByBrowser,
}: {
  omsOrderDetail: OmsOrderDetail;
  onPrintReceiptByBrowser: () => void;
}) => {
  const { orderItemList } = omsOrderDetail;
  const [{ actualCurrency }] = orderItemList;
  const { posPrinterInfo } = usePosPrinter();

  // 目前只有日本的商品，支持打印小票
  if (actualCurrency !== CURRENCY_MAP.JPY) {
    return null;
  }

  return (
    <Button
      loading={posPrinterInfo.status === 'connecting'}
      type="primary"
      onClick={async () => {
        try {
          await connectToPosPrinter();
          printOrderReceipt({ orderId: omsOrderDetail.id });
        } catch (error) {
          onPrintReceiptByBrowser();
        }
      }}
    >
      {i18n.t('print_receipt')}
    </Button>
  );
};

export default PrintReceiptButton;
