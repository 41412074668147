import { Descriptions, Tag } from 'antd';
import LOCALS from 'commons/locals';
import {
  ORDER_CREATED_FROM_OPTION_LIST,
  ORDER_STATUS_ANTD_TAG_COLOR_MAP,
  OrderDeliveryTypeOptionList,
  findLabelByValue,
} from 'commons/options';
import CopyButton from 'components/copy-button';
import LinkButton from 'components/link-button';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { OmsOrderDetail } from 'types/oms';
import formatTime from 'utils/formatTime';
import styles from './index.module.scss';

type OrderBaseInfoProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OrderBaseInfo = ({ omsOrderDetail }: OrderBaseInfoProps) => {
  const {
    status,
    orderSn,
    memberUsername,
    createdFrom,
    deliveryCompany,
    deliverySn,
    integration,
    memberId,
    multiplePayStatus,
    deliveryType,
    createTime,
    id,
  } = omsOrderDetail;
  const { orderStatusOptions, orderTypeOptions } =
    useAppSelector(selectGlobalInfo);

  return (
    <div className="mb-3">
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.basic_info} />
      </div>
      <Descriptions size="small" bordered>
        <Descriptions.Item label={<Trans i18nKey={LOCALS.order_status} />}>
          <Tag color={ORDER_STATUS_ANTD_TAG_COLOR_MAP[status]}>
            {findLabelByValue(status, orderStatusOptions)}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.dwawuQNUEi} />}>
          {id}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.order_sn} />}>
          <CopyButton copyText={orderSn}>{orderSn}</CopyButton>
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.created_time} />}>
          {formatTime(createTime)}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.member_account} />}>
          {memberId && memberUsername ? (
            <CopyButton copyText={memberUsername}>
              <LinkButton href={`/ums/member-view/${memberId}`}>
                {memberUsername}
              </LinkButton>
            </CopyButton>
          ) : (
            '-'
          )}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.created_from} />}>
          {findLabelByValue(createdFrom, ORDER_CREATED_FROM_OPTION_LIST)}
        </Descriptions.Item>

        {!!deliveryCompany && (
          <Descriptions.Item label={<Trans i18nKey={LOCALS.delivery_method} />}>
            {deliveryCompany || '-'}
          </Descriptions.Item>
        )}

        {!!deliverySn && (
          <Descriptions.Item label={<Trans i18nKey={LOCALS.shipment_number} />}>
            {deliverySn || '-'}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={<Trans i18nKey={LOCALS.points_for_order} />}>
          {integration || 0}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.order_type} />}>
          {findLabelByValue(omsOrderDetail.orderType, orderTypeOptions)}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.vucsrXTzfQ}></Trans>}>
          <Tag
            color={
              OrderDeliveryTypeOptionList.find((d) => d.value === deliveryType)
                ?.color
            }
          >
            {findLabelByValue(deliveryType, OrderDeliveryTypeOptionList)}
          </Tag>
        </Descriptions.Item>

        {!!multiplePayStatus && (
          <Descriptions.Item label={<Trans i18nKey={LOCALS.MultiplePayList} />}>
            {multiplePayStatus ? <Trans i18nKey={LOCALS.yes} /> : '-'}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};

export default OrderBaseInfo;
