import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  GetOrderListDto,
  GetOrderListV2Res,
  getOrderListV2,
  getOrderStatusStatistics,
} from 'apis/oms';
import usePagination from 'commons/hooks/usePagination';
import LOCALS from 'commons/locals';
import {
  ORDER_CREATED_FROM_OPTION_LIST,
  ORDER_STATUS_ANTD_TAG_COLOR_MAP,
  ORDER_STATUS_MAP,
  OrderDeliveryTypeOptionList,
  findLabelByValue,
} from 'commons/options';
import LinkButton from 'components/link-button';
import showTotal from 'components/show-total';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { PageQuery } from 'types/base';
import formatTime from 'utils/formatTime';
import setQueryParameters from 'utils/setQueryParameters';
import queryString from 'query-string';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import { CURRENCY_ENUM } from 'types/pms';

const OrderList = () => {
  const [form] = Form.useForm<
    Omit<GetOrderListDto, 'createTime'> & {
      createTime: Dayjs;
    }
  >();
  const navigate = useNavigate();
  const statusFormValue = Form.useWatch('status', form);
  const createdFromsFormValue = Form.useWatch('createdFroms', form);

  const { orderTypeOptions } = useAppSelector(selectGlobalInfo);
  const [orderStatusStatistics, setOrderStatusStatistics] = useState<
    {
      count: string;
      status: number;
    }[]
  >([]);

  const debounceGetOrderStatusStatistics = useMemo(() => {
    return debounce((createdFroms?: number[]) => {
      getOrderStatusStatistics({ createdFroms }).then((res) => {
        setOrderStatusStatistics(res);
      });
    }, 300);
  }, []);

  useEffect(() => {
    debounceGetOrderStatusStatistics(createdFromsFormValue);
  }, [createdFromsFormValue, debounceGetOrderStatusStatistics]);

  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<GetOrderListV2Res['list'][number]>();
  const { orderStatusOptions } = useAppSelector(selectGlobalInfo);

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      const formData = form.getFieldsValue();
      const data = {
        ...form.getFieldsValue(),
        createTime: formData.createTime
          ? dayjs(formData.createTime).startOf('days').format()
          : undefined,
        pageNum,
        pageSize,
      };

      setQueryParameters(data);

      try {
        setLoading(true);
        const { list, total } = await getOrderListV2(data);
        setDataSource(list);
        setTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [form, setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    if (parsed.status) {
      // @ts-ignore
      parsed.status = +parsed.status;
    }

    if (parsed.createdFroms) {
      // @ts-ignore
      parsed.createdFroms = parsed.createdFroms.split(',').map((i) => {
        return +i;
      });
    }

    if (parsed.createTime) {
      // @ts-ignore
      parsed.createTime = dayjs(parsed.createTime);
    }

    form.setFieldsValue(parsed);
    const pageNum = parsed.pageNum ? +parsed.pageNum : 1;
    const pageSize = parsed.pageSize ? +parsed.pageSize : 10;
    setPageNum(pageNum);
    setPageSize(pageSize);

    getDataSource({ pageNum, pageSize });
  }, [form, getDataSource, setPageNum, setPageSize]);

  const onFinish = async () => {
    setPageNum(1);
    getDataSource({ pageNum: 1, pageSize });
  };

  const onReset = () => {
    form.resetFields();
    onFinish();
  };

  const columns: ColumnsType<GetOrderListV2Res['list'][number]> =
    useMemo(() => {
      return [
        {
          title: <Trans i18nKey={LOCALS.dwawuQNUEi}></Trans>,
          dataIndex: 'id',
          key: 'id',
          width: 80,
          render: (id: GetOrderListV2Res['list'][number]['id']) => {
            return <LinkButton href={`/oms/order-view/${id}`}>{id}</LinkButton>;
          },
        },
        {
          title: <Trans i18nKey={LOCALS.order_sn}></Trans>,
          dataIndex: 'orderSn',
          key: 'orderSn',
          width: 140,
        },
        {
          title: <Trans i18nKey={LOCALS.product_pictures}></Trans>,
          key: 'product_pictures',
          width: 150,
          render: ({ orderItems }: GetOrderListV2Res['list'][number]) => {
            return (
              <div className="flex gap-2">
                {orderItems.map(({ productPic, productId }) => {
                  return (
                    <div key={productId} className="w-16 h-16">
                      {productPic ? (
                        <Image
                          src={productPic}
                          alt="product"
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: <Trans i18nKey={LOCALS.product_sn}></Trans>,
          dataIndex: 'orderItems',
          key: 'orderItems',
          width: 150,
          render: (
            orderItems: GetOrderListV2Res['list'][number]['orderItems']
          ) => {
            return (
              <div>
                {orderItems.map(({ product, productId }) => {
                  const href = `/pms/product-view/${productId}`;
                  return (
                    <div key={productId}>
                      <a
                        href={href}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(href);
                        }}
                      >
                        {`${product.productSn}${
                          product.description
                            ? `（${product.description}）`
                            : ''
                        }`}
                      </a>
                    </div>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: <Trans i18nKey={LOCALS.stock_place} />,
          key: 'stockPlace',
          width: 100,
          render: ({ orderItems }: GetOrderListV2Res['list'][number]) => {
            if (!orderItems.length) return '-';

            const currency = orderItems[0].actualCurrency || CURRENCY_ENUM.JPY;

            if (currency === CURRENCY_ENUM.JPY) {
              return <Trans i18nKey={LOCALS.japan} />;
            }

            if (currency === CURRENCY_ENUM.HKD) {
              return <Trans i18nKey={LOCALS.hongkong} />;
            }

            if (currency === CURRENCY_ENUM.SGD) {
              return <Trans i18nKey={LOCALS.singapore} />;
            }

            return '-';
          },
        },
        {
          title: <Trans i18nKey={LOCALS.email} />,
          key: 'memberUsername',
          width: 220,
          render: ({
            memberUsername,
            memberId,
          }: GetOrderListV2Res['list'][number]) => {
            if (!memberId) return '-';

            return (
              <LinkButton href={`/ums/member-view/${memberId}`}>
                {memberUsername}
              </LinkButton>
            );
          },
        },
        {
          title: <Trans i18nKey={LOCALS.order_type} />,
          dataIndex: 'orderType',
          key: 'orderType',
          width: 100,
          render: (
            orderType: GetOrderListV2Res['list'][number]['orderType']
          ) => {
            return findLabelByValue(orderType, orderTypeOptions);
          },
        },
        {
          title: <Trans i18nKey={LOCALS.pay_amount}></Trans>,
          key: 'payAmount',
          width: 150,
          render: ({
            payAmount,
            payAmountActualCurrency,
            orderItems: [{ actualCurrency }],
          }: GetOrderListV2Res['list'][number]) => {
            if (actualCurrency && payAmountActualCurrency !== null) {
              return `${actualCurrency} ${payAmountActualCurrency.toLocaleString()}`;
            }

            return `JPY ${payAmount.toLocaleString()}`;
          },
        },
        {
          title: <Trans i18nKey={LOCALS.created_from}></Trans>,
          dataIndex: 'createdFrom',
          key: 'createdFrom',
          width: 100,
          render: (
            createdFrom: GetOrderListV2Res['list'][number]['createdFrom']
          ) => {
            return findLabelByValue(
              createdFrom,
              ORDER_CREATED_FROM_OPTION_LIST
            );
          },
        },
        {
          title: <Trans i18nKey={LOCALS.vucsrXTzfQ}></Trans>,
          dataIndex: 'deliveryType',
          key: 'deliveryType',
          width: 100,
          render: (
            deliveryType: GetOrderListV2Res['list'][number]['deliveryType']
          ) => {
            return findLabelByValue(deliveryType, OrderDeliveryTypeOptionList);
          },
        },
        {
          title: <Trans i18nKey={LOCALS.created_time}></Trans>,
          dataIndex: 'createTime',
          key: 'createTime',
          width: 170,
          render: (
            createTime: GetOrderListV2Res['list'][number]['createTime']
          ) => {
            return formatTime(createTime);
          },
        },
        {
          title: <Trans i18nKey={LOCALS.MultiplePayList}></Trans>,
          dataIndex: 'multiplePayStatus',
          width: 100,
          key: 'multiplePayStatus',
          render: (
            value: GetOrderListV2Res['list'][number]['multiplePayStatus']
          ) => {
            return value ? <Trans i18nKey={LOCALS.yes} /> : '-';
          },
        },
        {
          title: <Trans i18nKey={LOCALS.order_status}></Trans>,
          dataIndex: 'status',
          width: 120,
          key: 'status',
          fixed: 'right',
          render: (status: GetOrderListV2Res['list'][number]['status']) => {
            return (
              <Tag color={ORDER_STATUS_ANTD_TAG_COLOR_MAP[status]}>
                {findLabelByValue(status, orderStatusOptions)}
              </Tag>
            );
          },
        },
      ];
    }, [navigate, orderStatusOptions, orderTypeOptions]);

  return (
    <div>
      <Form form={form} layout={'inline'} onFinish={onFinish}>
        <Form.Item label={<Trans i18nKey={LOCALS.KaLvBciebv} />} name="orderSn">
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.jHkqqeaSOv} />}
          name="productSn"
        >
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.created_time} />}
          name="createTime"
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.created_from} />}
          name="createdFroms"
        >
          {/* TODO: 不同店铺账号之间数据权限，只能查看自己店铺的订单 */}
          <Select
            mode="multiple"
            style={{ minWidth: 150 }}
            placeholder={i18n.t(LOCALS.please_select)}
            options={ORDER_CREATED_FROM_OPTION_LIST}
          />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.order_status} />}
          name="status"
          hidden
        >
          <Select
            allowClear
            placeholder={i18n.t(LOCALS.please_select)}
            options={orderStatusOptions}
            style={{ width: 150 }}
          />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.vucsrXTzfQ}></Trans>}
          name="deliveryType"
        >
          <Select
            allowClear
            placeholder={i18n.t(LOCALS.please_select)}
            options={OrderDeliveryTypeOptionList}
            style={{ width: 150 }}
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              <Trans i18nKey={LOCALS.search} />
            </Button>

            <Button htmlType="button" onClick={onReset}>
              <Trans i18nKey={LOCALS.reset} />
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <div className="grid grid-cols-2 lg:grid-cols-6 gap-4">
        {[
          ORDER_STATUS_MAP.TO_BE_PAID,
          ORDER_STATUS_MAP.TO_BE_DELIVERED,
          ORDER_STATUS_MAP.DELIVERED,
          ORDER_STATUS_MAP.COMPLETED,
          ORDER_STATUS_MAP.REFUND_ORDER,
          ORDER_STATUS_MAP.CANCLED,
        ].map((status) => {
          const isChecked = statusFormValue === status;

          return (
            <div
              key={status}
              className={classNames(
                'text-center py-2 cursor-pointer',
                isChecked ? 'bg-black text-white' : 'bg-gray-200'
              )}
              onClick={() => {
                if (isChecked) {
                  form.setFieldValue('status', undefined);
                } else {
                  form.setFieldValue('status', status);
                }

                onFinish();
              }}
            >
              {findLabelByValue(status, orderStatusOptions)}（
              {orderStatusStatistics.find((i) => i.status === status)?.count ||
                0}
              ）
            </div>
          );
        })}
      </div>

      <Table
        bordered
        tableLayout="fixed"
        pagination={{
          showTotal,
          total,
          pageSize,
          current: pageNum,
          onChange: (page, pageSize) => {
            setPageNum(page);
            setPageSize(pageSize);
            getDataSource({ pageNum: page, pageSize });
          },
        }}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default OrderList;
