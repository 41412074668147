const XiaoMaLogoSvg = () => {
  return (
    <svg
      id="グループ_18"
      data-name="グループ 18"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="178.691"
      height="52.085"
      viewBox="0 0 178.691 52.085"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="長方形_18"
            data-name="長方形 18"
            width="178.691"
            height="52.085"
          />
        </clipPath>
      </defs>
      <rect
        id="長方形_16"
        data-name="長方形 16"
        width="5.226"
        height="36.377"
        transform="translate(36.609 0.013)"
      />
      <g id="グループ_16" data-name="グループ 16">
        <g id="グループ_15" data-name="グループ 15" clipPath="url(#clip-path)">
          <path
            id="パス_41"
            data-name="パス 41"
            d="M378.746.093a1.484,1.484,0,0,0-.325-.034H367.8a1.84,1.84,0,0,0-.5.065c-5.981,1.69-9.675,10.373-9.681,18.074-.006,8.15,4.033,16.932,10.342,18.232a2.365,2.365,0,0,0,.477.045H378.5a2.222,2.222,0,0,0,.569-.069c6.051-1.6,9.679-9.966,9.835-18.118.164-8.587-3.928-16.8-10.154-18.2M375.961,34.9a4.966,4.966,0,0,1-5.617-.058c-4.288-2.994-6.911-9.279-6.911-16.552,0-7.323,2.661-13.648,7-16.618a5.11,5.11,0,0,1,5.864.061c4.224,3.027,6.788,9.262,6.788,16.467,0,7.392-2.72,13.766-7.123,16.7"
            transform="translate(-281.13 -0.046)"
          />
          <path
            id="パス_42"
            data-name="パス 42"
            d="M32.288,0H26.2L16.743,16.544a.191.191,0,0,1-.332,0L6.958,0H.866l9.981,16.988L0,36.366H6.1L16.426,17.428a.173.173,0,0,1,.3,0L27.055,36.366h6.064L22.3,16.988Z"
          />
          <path
            id="パス_43"
            data-name="パス 43"
            d="M539.862,10.692a.209.209,0,0,1-.366,0c-.887-1.664-5.663-10.58-5.663-10.58H523.289V36.474l5.083.008L528.356.719a.112.112,0,0,1,.211-.055c2.308,4.29,8.774,15.624,10.614,18.843a.565.565,0,0,0,.983,0c1.989-3.529,9.42-16.715,10.625-18.9a.118.118,0,0,1,.222.059l-.027,35.812h5.082V.109H545.52Z"
            transform="translate(-411.367 -0.086)"
          />
          <path
            id="パス_44"
            data-name="パス 44"
            d="M219.819,0,209.441,36.386h5.318l3.8-13.27.034-.119h13.08l3.83,13.393h5.338L230.436.013Zm.023,18.632L225,.614a.141.141,0,0,1,.271,0l5.152,18.017Z"
            transform="translate(-164.645)"
          />
          <path
            id="パス_45"
            data-name="パス 45"
            d="M709.653.013,699.036,0,688.658,36.386h5.318l3.8-13.27.034-.119h13.08l3.83,13.393h5.338ZM699.059,18.632,704.246.514a.111.111,0,0,1,.214,0l4.474,15.645.707,2.473Z"
            transform="translate(-541.367)"
          />
          <path
            id="パス_46"
            data-name="パス 46"
            d="M311.852,203.676h2.412v1.765a3.85,3.85,0,0,1-2.277.714,3.154,3.154,0,0,1-3.207-3.382,3.186,3.186,0,0,1,3.059-3.342,3.7,3.7,0,0,1,2.641,1.011l1.051-1.253a5.093,5.093,0,0,0-3.625-1.28,4.809,4.809,0,0,0-4.865,4.891,4.684,4.684,0,0,0,4.892,4.865,6.014,6.014,0,0,0,3.935-1.469v-3.962h-4.016Z"
            transform="translate(-241.37 -155.579)"
          />
          <rect
            id="長方形_17"
            data-name="長方形 17"
            width="1.658"
            height="9.433"
            transform="translate(77.757 42.49)"
          />
          <path
            id="パス_47"
            data-name="パス 47"
            d="M394.4,205.186l-5.053-6.522h-1.536V208.1h1.63v-6.711l5.2,6.711h1.388v-9.433H394.4Z"
            transform="translate(-304.861 -156.173)"
          />
          <path
            id="パス_48"
            data-name="パス 48"
            d="M440.9,200.133h5.39l-5.565,6.738V208.1h7.721v-1.469h-5.565l5.565-6.738v-1.226H440.9Z"
            transform="translate(-346.463 -156.173)"
          />
          <path
            id="パス_49"
            data-name="パス 49"
            d="M486.581,198.349l-4.151,9.5h1.7l.97-2.277h4.46l.957,2.277h1.752l-4.151-9.5ZM485.7,204.1l1.617-3.773,1.63,3.773Z"
            transform="translate(-379.247 -155.926)"
          />
        </g>
      </g>
    </svg>
  );
};

export default XiaoMaLogoSvg;
