const LOCALS = {
  'home_page': 'home_page',
  'log_out': 'log_out',
  'product': 'product',
  'product_list': 'product_list',
  'recycle': 'recycle',
  'sell_your_bag': 'sell_your_bag',
  'quotation_form': 'quotation_form',
  'keyword': 'keyword',
  'search': 'search',
  'reset': 'reset',
  'created_time': 'created_time',
  'product_pictures': 'product_pictures',
  'product_name': 'product_name',
  'member_info': 'member_info',
  'options': 'options',
  'details': 'details',
  'member_id': 'member_id',
  'email': 'email',
  'phone_number': 'phone_number',
  'social_media': 'social_media',
  'social_handle': 'social_handle',
  'accessories': 'accessories',
  'add': 'add',
  'please_enter': 'please_enter',
  'please_enter_remark': 'please_enter_remark',
  'please_select': 'please_select',
  'product_category': 'product_category',
  'color': 'color',
  'material': 'material',
  'submit': 'submit',
  'stamp': 'stamp',
  'rank': 'rank',
  'staff': 'staff',
  'product_info': 'product_info',
  'quotation_info': 'quotation_info',
  'jpy': 'jpy',
  'sgd': 'sgd',
  'hkd': 'hkd',
  'currency': 'currency',
  'recycle_price': 'recycle_price',
  'consignment_price': 'consignment_price',
  'substitute_price': 'substitute_price',
  'deal_price': 'deal_price',
  'inventory': 'inventory',
  'in_stock': 'in_stock',
  'out_of_stock': 'out_of_stock',
  'customer_info': 'customer_info',
  'name': 'name',
  'other_info': 'other_info',
  'note': 'note',
  'consulting_source': 'consulting_source',
  'successful_operation': 'successful_operation',
  'date_range': 'date_range',
  'created_by': 'created_by',
  'edit': 'edit',
  'back': 'back',
  'delete': 'delete',
  'cancel': 'cancel',
  'confirm': 'confirm',
  'are_you_sure_to_delete': 'are_you_sure_to_delete',
  'bag_style': 'bag_style',
  'type': 'type',
  'customer_name': 'customer_name',
  'quotation_currency': 'quotation_currency',
  'inventory_info': 'inventory_info',
  'customer_email': 'customer_email',
  'product_add': 'product_add',
  'japan': 'japan',
  'hongkong': 'hongkong',
  'singapore': 'singapore',
  'stock_place': 'stock_place',
  'publish_status': 'publish_status',
  'on_shelf': 'on_shelf',
  'off_shelf': 'off_shelf',
  'on_sale': 'on_sale',
  'product_sn': 'product_sn',
  'price': 'price',
  'price_tax_free': 'price_tax_free',
  'brand': 'brand',
  'website': 'website',
  'ginza': 'ginza',
  'sns': 'sns',
  'created_from': 'created_from',
  'product_type': 'product_type',
  'consignment': 'consignment',
  'yes': 'yes',
  'no': 'no',
  'product_source': 'product_source',
  'original_price': 'original_price',
  'rank_desc': 'rank_desc',
  'hue': 'hue',
  'color_remark': 'color_remark',
  'material_remark': 'material_remark',
  'hardware': 'hardware',
  'hardware_remark': 'hardware_remark',
  'stamp_remark': 'stamp_remark',
  'measurement': 'measurement',
  'list_title': 'list_title',
  'list_remark': 'list_remark',
  'detail_title': 'detail_title',
  'detailed_page_description': 'detailed_page_description',
  'collections': 'collections',
  'view': 'view',
  'product_main_image': 'product_main_image',
  'confirm_submit': 'confirm_submit',
  'required_field': 'required_field',
  'member': 'member',
  'member_list': 'member_list',
  'member_level': 'member_level',
  'total_pay_amount': 'total_pay_amount',
  'points': 'points',
  'registration_time': 'registration_time',
  'first_name': 'first_name',
  'last_name': 'last_name',
  'gender': 'gender',
  'rather_not_say': 'rather_not_say',
  'male': 'male',
  'female': 'female',
  'first_name_katakana': 'first_name_katakana',
  'last_name_katakana': 'last_name_katakana',
  'birthday': 'birthday',
  'points_expiration_time': 'points_expiration_time',
  'country_region': 'country_region',
  'state_province_city': 'state_province_city',
  'detail_address': 'detail_address',
  'order_list': 'order_list',
  'order_sn': 'order_sn',
  'pay_amount': 'pay_amount',
  'order_status': 'order_status',
  'point_history': 'point_history',
  'change_amount': 'change_amount',
  'operator': 'operator',
  'shipping_address': 'shipping_address',
  'zip_code': 'zip_code',
  'default_address': 'default_address',
  'password': 'password',
  'repeat_password': 'repeat_password',
  'member_sign_up': 'member_sign_up',
  'order': 'order',
  'order_submitted': 'order_submitted',
  'order_paid': 'order_paid',
  'shipped': 'shipped',
  'completed': 'completed',
  'platform_delivery': 'platform_delivery',
  'modify_recipient_information': 'modify_recipient_information',
  'modify_mailing_information': 'modify_mailing_information',
  'remark': 'remark',
  'operation_remark': 'operation_remark',
  'basic_info': 'basic_info',
  'member_account': 'member_account',
  'delivery_method': 'delivery_method',
  'shipment_number': 'shipment_number',
  'points_for_order': 'points_for_order',
  'order_operation': 'order_operation',
  'recipient_information': 'recipient_information',
  'ship': 'ship',
  'complete_order': 'complete_order',
  'operate_histories': 'operate_histories',
  'stock': 'stock',
  'close_order': 'close_order',
  'bzsNZmtrmS': 'bzsNZmtrmS',
  'payment_info': 'payment_info',
  'order_total_amount': 'order_total_amount',
  'amount_to_be_paid': 'amount_to_be_paid',
  'points_deduction': 'points_deduction',
  'discount_amounts': 'discount_amounts',
  'online_payment_record': 'online_payment_record',
  'offline_payment_record': 'offline_payment_record',
  'payment_channel': 'payment_channel',
  'payment_status': 'payment_status',
  'payment_time': 'payment_time',
  'payment_voucher': 'payment_voucher',
  'payment_note': 'payment_note',
  'confirmor': 'confirmor',
  'confirm_time': 'confirm_time',
  'reject': 'reject',
  'offline_payment_record_confirm': 'offline_payment_record_confirm',
  'system': 'system',
  'account_list': 'account_list',
  'account': 'account',
  'last_login_time': 'last_login_time',
  'shop': 'shop',
  'log_in': 'log_in',
  'create_order': 'create_order',
  'zMANYPXpGr': 'zMANYPXpGr',
  'XeaiVflJZJ': 'XeaiVflJZJ',
  'order_detail': 'order_detail',
  'payment_currency': 'payment_currency',
  'aVYeAxIbsh': 'aVYeAxIbsh',
  'used_points': 'used_points',
  'equivalent_to': 'equivalent_to',
  'lXECTXrLEx': 'lXECTXrLEx',
  'convert_to_jpy': 'convert_to_jpy',
  'total_amount': 'total_amount',
  'points_earned': 'points_earned',
  'points_equivalent': 'points_equivalent',
  'LabelList': 'LabelList',
  'modify_points': 'modify_points',
  'start': 'start',
  'end': 'end',
  'add_price_tag': 'add_price_tag',
  'batch_print': 'batch_print',
  'print': 'print',
  'sales_code': 'sales_code',
  'edit_price_tag': 'edit_price_tag',
  'price_tag_preview': 'price_tag_preview',
  'price_tag_tip': 'price_tag_tip',
  'select_a_maximum_of_three': 'select_a_maximum_of_three',
  'select_a_maximum_of_two': 'select_a_maximum_of_two',
  'preview': 'preview',
  'product_name_1': 'product_name_1',
  'price_1': 'price_1',
  'option': 'option',
  'duplicate_item_number': 'duplicate_item_number',
  'MultiplePayList': 'MultiplePayList',
  'MultiplePayListSet': 'MultiplePayListSet',
  'sortId': 'sortId',
  'needPayAmount': 'needPayAmount',
  'orderPayId': 'orderPayId',
  'Auto': 'Auto',
  'AutoSplit': 'AutoSplit',
  'saveFirst': 'saveFirst',
  'cate_no': 'cate_no',
  'category_name': 'category_name',
  'level': 'level',
  'navigation_bar': 'navigation_bar',
  'sidebar_filter': 'sidebar_filter',
  'sort': 'sort',
  'set_up': 'set_up',
  'check_subordinates': 'check_subordinates',
  'enable': 'enable',
  'disable': 'disable',
  'addTo': 'addTo',
  'classification_description': 'classification_description',
  'category_name_En': 'category_name_En',
  'category_name_Ja': 'category_name_Ja',
  'category_name_Cn': 'category_name_Cn',
  'category_name_Tw': 'category_name_Tw',
  'category_value': 'category_value',
  'category_ancestor': 'category_ancestor',
  'category_parent': 'category_parent',
  'size_En': 'size_En',
  'size_Ja': 'size_Ja',
  'size_Cn': 'size_Cn',
  'size_Tw': 'size_Tw',
  'category_nav_status': 'category_nav_status',
  'category_filter_status': 'category_filter_status',
  'category_enable': 'category_enable',
  'category_icon': 'category_icon',
  'extension_configuration_json': 'extension_configuration_json',
  'cate_options': 'cate_options',
  'product_categories': 'product_categories',
  'no_superior_classification': 'no_superior_classification',
  'ojMPRgDisg': 'ojMPRgDisg',
  'kkIwEnEVml': 'kkIwEnEVml',
  'ginza_shop': 'ginza_shop',
  'hongkong_shop': 'hongkong_shop',
  'singapore_shop': 'singapore_shop',
  'product_list_mobile': 'product_list_mobile',
  'support_cryptocurrency_payments': 'support_cryptocurrency_payments',
  'set_the': 'set_the',
  'main_image': 'main_image',
  'total_products': 'total_products',
  'copy_link': 'copy_link',
  'add_sales_link': 'add_sales_link',
  'expired_hours': 'expired_hours',
  'print_prompt': 'print_prompt',
  'print_position': 'print_position',
  'print_settings': 'print_settings',
  'order_type': 'order_type',
  'order_list_mobile': 'order_list_mobile',
  'order_total': 'order_total',
  'pending_payment': 'pending_payment',
  'pending_payment_r': 'pending_payment_r',
  'payment_processed': 'payment_processed',
  'all_orders': 'all_orders',
  'member_details': 'member_details',
  'product_selection': 'product_selection',
  'payment_settings': 'payment_settings',
  'phone_or_email': 'phone_or_email',
  'sku_or_keywords': 'sku_or_keywords',
  'enter_use_points': 'enter_use_points',
  'use_points': 'use_points',
  'enter_discount_amount': 'enter_discount_amount',
  'multiple_pay_count': 'multiple_pay_count',
  'first_amount': 'first_amount',
  'add_multi_amount': 'add_multi_amount',
  'add_pay_amount': 'add_pay_amount',
  'clear': 'clear',
  'generate': 'generate',
  'to_order_details': 'to_order_details',
  'pay_count': 'pay_count',
  'text_lg': 'text_lg',
  'maximum_limit_per_transaction': 'maximum_limit_per_transaction',
  'transaction_range': 'transaction_range',
  'recycling_consignment_order_history': 'recycling_consignment_order_history',
  'shop_id': 'shop_id',
  'email_history': 'email_history',
  'subject': 'subject',
  'content': 'content',
  'send_time': 'send_time',
  'appointment_management_list': 'appointment_management_list',
  'appointment_management_config': 'appointment_management_config',
  'contract_order': 'contract_order',
  'intentional_order': 'intentional_order',
  'all': 'all',
  'sell_consignment': 'sell_consignment',
  'sell': 'sell',
  'order_progress': 'order_progress',
  'inquiry_order': 'inquiry_order',
  'priority': 'priority',
  'collapse': 'collapse',
  'expand': 'expand',
  'pending_initial_appraisal': 'pending_initial_appraisal',
  'initial_appraisal': 'initial_appraisal',
  'dispatched': 'dispatched',
  'pending_final_appraisal': 'pending_final_appraisal',
  'pending_customer_confirmation': 'pending_customer_confirmation',
  'on_consignment': 'on_consignment',
  'pending_return': 'pending_return',
  'valuation_expired': 'valuation_expired',
  'transaction_completed': 'transaction_completed',
  'quoted_amount': 'quoted_amount',
  'initial_appraisal_submission_time': 'initial_appraisal_submission_time',
  'agreed_initial_appraisal_time': 'agreed_initial_appraisal_time',
  'quotation_time': 'quotation_time',
  'contract_establishment_time': 'contract_establishment_time',
  'pending_payment_amount': 'pending_payment_amount',
  'confirmation_settlement_time': 'confirmation_settlement_time',
  'cancellation_reason': 'cancellation_reason',
  'cancellation_time': 'cancellation_time',
  'completion_time': 'completion_time',
  'instant_sale': 'instant_sale',
  'consignment_preliminary_valuation': 'consignment_preliminary_valuation',
  'waiting_store_arrival': 'waiting_store_arrival',
  'upload_packing_slip': 'upload_packing_slip',
  'confirm_receipt': 'confirm_receipt',
  'consignment_pending': 'consignment_pending',
  'consignment_in_progress': 'consignment_in_progress',
  'recycling_confirmation_quote': 'recycling_confirmation_quote',
  'consignment_confirmation_quote': 'consignment_confirmation_quote',
  'final_quote': 'final_quote',
  'confirm_settlement': 'confirm_settlement',
  'modify_quote': 'modify_quote',
  'upload_payment_proof': 'upload_payment_proof',
  'confirm_return': 'confirm_return',
  're_evaluate': 're_evaluate',
  'cancelled': 'cancelled',
  'payment_made': 'payment_made',
  'preliminary_valuation_completed': 'preliminary_valuation_completed',
  'submit_preliminary_valuation': 'submit_preliminary_valuation',
  'product_information': 'product_information',
  'service_comments': 'service_comments',
  'inventory_notes': 'inventory_notes',
  'historical_valuation': 'historical_valuation',
  'save_and_copy': 'save_and_copy',
  'quote': 'quote',
  'email_notes': 'email_notes',
  'select_at_least_one': 'select_at_least_one',
  'filled_in_at_least_one': 'filled_in_at_least_one',
  'consignment_valuation_minimum': 'consignment_valuation_minimum',
  'consignment_valuation_w': 'consignment_valuation_w',
  'consignment_valuation_maximum': 'consignment_valuation_maximum',
  'fill_in_completely': 'fill_in_completely',
  'invalid_range': 'invalid_range',
  'cancel_order': 'cancel_order',
  'intention_confirmation_time': 'intention_confirmation_time',
  'contract_confirmation_time': 'contract_confirmation_time',
  'cancel_order_time': 'cancel_order_time',
  'transaction_completed_time': 'transaction_completed_time',
  'associated_sales_order': 'associated_sales_order',
  'associated_product_id': 'associated_product_id',
  'channel': 'channel',
  'id_passport_number': 'id_passport_number',
  'user_submitted_product_info': 'user_submitted_product_info',
  'product_image_gallery': 'product_image_gallery',
  'genuine_product_info': 'genuine_product_info',
  'valuation_information': 'valuation_information',
  'initial_appraisal_time': 'initial_appraisal_time',
  'quotation_confirmation_time': 'quotation_confirmation_time',
  'postal_code': 'postal_code',
  'mailing_information': 'mailing_information',
  'cargo_information': 'cargo_information',
  'receiving_status': 'receiving_status',
  'receipt_photo': 'receipt_photo',
  'receiving_time': 'receiving_time',
  'recipient': 'recipient',
  'logistics_information': 'logistics_information',
  'modify_logistics_information': 'modify_logistics_information',
  'update_valuation_information': 'update_valuation_information',
  'return_information': 'return_information',
  'express_company_name': 'express_company_name',
  'tracking_number': 'tracking_number',
  'return_type': 'return_type',
  'return_time': 'return_time',
  'payment_information': 'payment_information',
  'update_payment_information': 'update_payment_information',
  'receiving_account': 'receiving_account',
  'payment_type': 'payment_type',
  'location': 'location',
  'bank_name': 'bank_name',
  'bank_address': 'bank_address',
  'account_holder_name': 'account_holder_name',
  'account_number': 'account_number',
  'account_holder_address': 'account_holder_address',
  'bank_transfer': 'bank_transfer',
  'payment_method': 'payment_method',
  'overseas_account': 'overseas_account',
  'hong_kong_account': 'hong_kong_account',
  'japanese_account': 'japanese_account',
  'singapore_account': 'singapore_account',
  'account_number_iban': 'account_number_iban',
  'identifier': 'identifier',
  'number': 'number',
  'paynow': 'paynow',
  'bank_code': 'bank_code',
  'contact_number': 'contact_number',
  'payment_amount': 'payment_amount',
  'fps': 'fps',
  'contract_information': 'contract_information',
  'contract_expiry_time': 'contract_expiry_time',
  'contract_content': 'contract_content',
  'contract_status': 'contract_status',
  'customer_version': 'customer_version',
  'store_version': 'store_version',
  'operation_log': 'operation_log',
  'modification_operation_record': 'modification_operation_record',
  'operation_content': 'operation_content',
  'operation_time': 'operation_time',
  'send_email': 'send_email',
  'select_template': 'select_template',
  'select_language': 'select_language',
  'english': 'english',
  'chinese': 'chinese',
  'japanese': 'japanese',
  'body': 'body',
  'logistics_certificate': 'logistics_certificate',
  'received_items': 'received_items',
  'caution': 'caution',
  'confirm_submission': 'confirm_submission',
  'save_basic_information': 'save_basic_information',
  'save_payee_info': 'save_payee_info',
  'payee_information': 'payee_information',
  'save_success': 'save_success',
  'final_quote_successful': 'final_quote_successful',
  'final_quote_rejection_successful': 'final_quote_rejection_successful',
  'previous': 'previous',
  'fill_recycling_confirmation_quote': 'fill_recycling_confirmation_quote',
  'contract_type': 'contract_type',
  'select_contract_type': 'select_contract_type',
  'must_include_consignment_confirmation_quote': 'must_include_consignment_confirmation_quote',
  'must_include_recycling_confirmation_quote': 'must_include_recycling_confirmation_quote',
  'cash': 'cash',
  'accounting_settlement': 'accounting_settlement',
  'submit_final_quote': 'submit_final_quote',
  'reason_for_rejection': 'reason_for_rejection',
  'consignment_item_amount': 'consignment_item_amount',
  'sales_order_number': 'sales_order_number',
  'please_confirm': 'please_confirm',
  'confirm_payment': 'confirm_payment',
  'payment_information_collection': 'payment_information_collection',
  'receiving_amount': 'receiving_amount',
  'return_information_collection': 'return_information_collection',
  'copy_return_information': 'copy_return_information',
  'return_method': 'return_method',
  'in_store_pickup': 'in_store_pickup',
  'mail': 'mail',
  'return_receipt': 'return_receipt',
  'submission_time': 'submission_time',
  'available_points': 'available_points',
  'sell_your_bag_order': 'sell_your_bag_order',
  'inquiry_order_progress': 'inquiry_order_progress',
  'intentional_order_progress': 'intentional_order_progress',
  'contract_order_progress': 'contract_order_progress',
  'upload_shiplable': 'upload_shiplable',
  'stock_availability': 'stock_availability',
  'available': 'available',
  'unavailable': 'unavailable',
  'traditional_chinese': 'traditional_chinese',
  'language_zh_tw': 'language_zh_tw',
  'language_zh_cn': 'language_zh_cn',
  'language_en': 'language_en',
  'language_ja': 'language_ja',
  'final_payment_settlement': 'final_payment_settlement',
  'copy_order_link': 'copy_order_link',
  'invalid_order': 'invalid_order',
  'view_packaging_materials_certificate': 'view_packaging_materials_certificate',
  'historical_documents': 'historical_documents',
  'packaging_materials_required': 'packaging_materials_required',
  'initial_appraisal_o': 'initial_appraisal_o',
  'checkout_counter': 'checkout_counter',
  'select_member': 'select_member',
  'enter_item_number': 'enter_item_number',
  'select_member_keyword': 'select_member_keyword',
  'price_type': 'price_type',
  'tax_inclusive': 'tax_inclusive',
  'tax_free': 'tax_free',
  'tax_amount': 'tax_amount',
  'cash_payment': 'cash_payment',
  'third_party_payment': 'third_party_payment',
  'other_payment_methods': 'other_payment_methods',
  'stock_place_warning_1': 'stock_place_warning_1',
  'stock_place_warning_2': 'stock_place_warning_2',
  'discount_details': 'discount_details',
  'price_adjustment': 'price_adjustment',
  'total': 'total',
  'discounted': 'discounted',
  'add_payment': 'add_payment',
  'clear_payment_data': 'clear_payment_data',
  'clear_order': 'clear_order',
  'non_deposit': 'non_deposit',
  'deposit': 'deposit',
  'remaining_amount': 'remaining_amount',
  'enter_payment_information': 'enter_payment_information',
  'select_representative': 'select_representative',
  'select_items': 'select_items',
  'order_amount': 'order_amount',
  'today': 'today',
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'sunday': 'sunday',
  'appointment_overview': 'appointment_overview',
  'weekly_appointments_total': 'weekly_appointments_total',
  'arrived': 'arrived',
  'booked': 'booked',
  'no_show': 'no_show',
  'appointment_arrival_time': 'appointment_arrival_time',
  'quantity_of_products': 'quantity_of_products',
  'status': 'status',
  'appointment_details': 'appointment_details',
  'copy': 'copy',
  'appointment_number': 'appointment_number',
  'no_new_appointments_allowed': 'no_new_appointments_allowed',
  'appointment_time_slot': 'appointment_time_slot',
  'appointment_method': 'appointment_method',
  'product_photos': 'product_photos',
  'add_appointment_information': 'add_appointment_information',
  'fill_in_customer_name': 'fill_in_customer_name',
  'fill_in_phone_number': 'fill_in_phone_number',
  'fill_in_email': 'fill_in_email',
  'search_member_information': 'search_member_information',
  'appointment_date_configuration': 'appointment_date_configuration',
  'date': 'date',
  'time_slot': 'time_slot',
  'payment_completed': 'payment_completed',
  'goods_received': 'goods_received',
  'normal_transaction': 'normal_transaction',
  'in_effect': 'in_effect',
  'user': 'user',
  'appointment_information': 'appointment_information',
  'unused': 'unused',
  'secondhand': 'secondhand',
  'consignment_valuation': 'consignment_valuation',
  'recycling_valuation': 'recycling_valuation',
  'manual_addition': 'manual_addition',
  'appointment_slot_editing': 'appointment_slot_editing',
  'cryptocurrency_payment_records': 'cryptocurrency_payment_records',
  'third_party_transaction_id': 'third_party_transaction_id',
  'payment_link': 'payment_link',
  'cryptocurrency_amount': 'cryptocurrency_amount',
  'quantity': 'quantity',
  'cancel_appointment': 'cancel_appointment',
  'code': 'code',
  'selling_price': 'selling_price',
  'purchase_price': 'purchase_price',
  'procurement': 'procurement',
  'facebookEnabled': 'facebookEnabled',
  'zh_CN': 'zh_CN',
  'zh_TW': 'zh_TW',
  'ja': 'ja',
  'en': 'en',
  'Hardware': 'Hardware',
  'Minor_scratches': 'Minor_scratches',
  'Small_scratches': 'Small_scratches',
  'Scratches': 'Scratches',
  'The_hardware_has_no_protective_film': 'The_hardware_has_no_protective_film',
  'The_hardware_has_the_original_protective_film': 'The_hardware_has_the_original_protective_film',
  'Edges': 'Edges',
  'Slight_scuff_on_all_corners': 'Slight_scuff_on_all_corners',
  'Scuff_on_all_corners': 'Scuff_on_all_corners',
  'Slight_stains_on_all_corners': 'Slight_stains_on_all_corners',
  'Stains_on_all_corners': 'Stains_on_all_corners',
  'Slight_scuff_on_corner': 'Slight_scuff_on_corner',
  'Scuff_on_corner': 'Scuff_on_corner',
  'Slight_stains_on_corner': 'Slight_stains_on_corner',
  'Stains_on_corner': 'Stains_on_corner',
  'Outer': 'Outer',
  'Overall_slight_signs_of_use': 'Overall_slight_signs_of_use',
  'Overall_signs_of_use': 'Overall_signs_of_use',
  'Outer_slight_scratches_from_use': 'Outer_slight_scratches_from_use',
  'Outer_normal_scratches_from_use': 'Outer_normal_scratches_from_use',
  'Overall_slight_deformation': 'Overall_slight_deformation',
  'Overall_deformation': 'Overall_deformation',
  'Overall_slight_stains': 'Overall_slight_stains',
  'Overall_stains': 'Overall_stains',
  'Inner': 'Inner',
  'Inner_slight_signs_of_use': 'Inner_slight_signs_of_use',
  'Inner_signs_of_use': 'Inner_signs_of_use',
  'Inner_slight_stains': 'Inner_slight_stains',
  'Inner_stains': 'Inner_stains',
  'Slight_indentations_from_use': 'Slight_indentations_from_use',
  'Normal_indentations_from_use': 'Normal_indentations_from_use',
  'Indentations': 'Indentations',
  'Slight_scratches_from_use': 'Slight_scratches_from_use',
  'Normal_scratches_from_use': 'Normal_scratches_from_use',
  'Ink_marks': 'Ink_marks',
  'Odor': 'Odor',
  'Handle': 'Handle',
  'Handle_small_scratches': 'Handle_small_scratches',
  'Handle_scratches': 'Handle_scratches',
  'Slight_stains_on_the_handle': 'Slight_stains_on_the_handle',
  'Stains_on_the_handle': 'Stains_on_the_handle',
  'Deformation': 'Deformation',
  'Discoloration': 'Discoloration',
  'Slight_peeling_on_the_edge': 'Slight_peeling_on_the_edge',
  'Highlights': 'Highlights',
  'Discontinued_model': 'Discontinued_model',
  'Discontinued_leather': 'Discontinued_leather',
  'No_deformation_on_the_body': 'No_deformation_on_the_body',
  'Protective_film_on_the_bottom_studs': 'Protective_film_on_the_bottom_studs',
  'Protective_film_on_the_metal_parts': 'Protective_film_on_the_metal_parts',
  'Interior_is_clean_no_scratches': 'Interior_is_clean_no_scratches',
  'No_wear_on_the_corners': 'No_wear_on_the_corners',
  'one_click_translation': 'one_click_translation',
  'add_member': 'add_member',
  'front': 'front',
  'back1': 'back1',
  'interior': 'interior',
  'blind_stamp': 'blind_stamp',
  'signs_of_wear': 'signs_of_wear',
  'upload_more': 'upload_more',
  'required': 'required',
  'select_address': 'select_address',
  'enter_address_manually': 'enter_address_manually',
  'valuation': 'valuation',
  'consignment_contract': 'consignment_contract',
  'recovery_contract': 'recovery_contract',
  'one_click_completion': 'one_click_completion',
  'unconfirmed': 'unconfirmed',
  'create_sales_order': 'create_sales_order',
  'creating_recycling_consignment_sales_contract': 'creating_recycling_consignment_sales_contract',
  'creating_intent_order_for_recycling_consignment_sales': 'creating_intent_order_for_recycling_consignment_sales',
  'print_receipt': 'print_receipt',
  'coupon': 'coupon',
  'enter_the_coupon': 'enter_the_coupon',
  'order_source': 'order_source',
  'item': 'item',
  'enter_order_source': 'enter_order_source',
  'hint': 'hint',
  'changes_have_not_been_saved': 'changes_have_not_been_saved',
  'jVFAzYMWJd': 'jVFAzYMWJd',
  'JSRDwAEqdy': 'JSRDwAEqdy',
  'pqepZTOsNb': 'pqepZTOsNb',
  'zYZFfigLSj': 'zYZFfigLSj',
  'vDnOozVzna': 'vDnOozVzna',
  'kTFIViDbSC': 'kTFIViDbSC',
  'PVkdenXCwb': 'PVkdenXCwb',
  'AYTHzloOiY': 'AYTHzloOiY',
  'print_the_contract': 'print_the_contract',
  'please_select_a_member': 'please_select_a_member',
  'the_place_of_origin_or_currency_is_incorrect': 'the_place_of_origin_or_currency_is_incorrect',
  'sop': 'sop',
  'checkout_counter_sop': 'checkout_counter_sop',
  'order_create_sop': 'order_create_sop',
  'guest_remarks': 'guest_remarks',
  'contract': 'contract',
  'receipt': 'receipt',
  'consignment_orders_and': 'consignment_orders_and',
  'freight': 'freight',
  'please_select_a_shipping_address': 'please_select_a_shipping_address',
  'add_address': 'add_address',
  'show_to_the_guest': 'show_to_the_guest',
  'supports_up_to_18_items': 'supports_up_to_18_items',
  'address': 'address',
  'khvsAbbLJs': 'khvsAbbLJs',
  'rank_desc_note': 'rank_desc_note',
  'expiration_time': 'expiration_time',
  'confirm_invalid_order_change': 'confirm_invalid_order_change',
  'invalid_order_points_adjustment': 'invalid_order_points_adjustment',
  'pointsMarketing': 'pointsMarketing',
  'orderPoints': 'orderPoints',
  'registrationPoints': 'registrationPoints',
  'pointsName': 'pointsName',
  'pointsType': 'pointsType',
  'activityPeriod': 'activityPeriod',
  'pointsQuantity': 'pointsQuantity',
  'collectionTime': 'collectionTime',
  'multiplier': 'multiplier',
  'recyclingContractOrder': 'recyclingContractOrder',
  'consignmentContractOrder': 'consignmentContractOrder',
  'noCategoryFound': 'noCategoryFound',
  'pricing_rule_1': 'pricing_rule_1',
  'pricing_rule_2': 'pricing_rule_2',
  'uMihwkqqts': 'uMihwkqqts',
  'cWgwnQtBhq': 'cWgwnQtBhq',
  'tPZNlIoLIJ': 'tPZNlIoLIJ',
  'ZNabMrkXeR': 'ZNabMrkXeR',
  'gCfzSuAAFu': 'gCfzSuAAFu',
  'qjvTkFPhtS': 'qjvTkFPhtS',
  'JTehSPdAjL': 'JTehSPdAjL',
  'aDOEGIrhvq': 'aDOEGIrhvq',
  'rrooMUILfK': 'rrooMUILfK',
  'mhIsJOVuUc': 'mhIsJOVuUc',
  'JlnzqcVRbm': 'JlnzqcVRbm',
  'bxYXkMWIQe': 'bxYXkMWIQe',
  'juusvCbRWo': 'juusvCbRWo',
  'ZQDTJFXciM': 'ZQDTJFXciM',
  'StzCTlyrrP': 'StzCTlyrrP',
  'kUJWWIcHRk': 'kUJWWIcHRk',
  'PWWODvsPvK': 'PWWODvsPvK',
  'rhCgCypWuW': 'rhCgCypWuW',
  'kWaTarLxzT': 'kWaTarLxzT',
  'bOYktSqeQU': 'bOYktSqeQU',
  'FrlyfzfGME': 'FrlyfzfGME',
  'AHBGzxjtrR': 'AHBGzxjtrR',
  'category': 'category',
  'path': 'path',
  'cover': 'cover',
  'title': 'title',
  'language': 'language',
  'article_publish_status': 'article_publish_status',
  'updated_time': 'updated_time',
  'article_category': 'article_category',
  'description': 'description',
  'article_content': 'article_content',
  'article_list': 'article_list',
  'topic_list': 'topic_list',
  'topic_category': 'topic_category',
  'recommended': 'recommended',
  'photo_album': 'photo_album',
  'copy_product_to': 'copy_product_to',
  'nikkei_table': 'nikkei_table',
  'sales_daily_report': 'sales_daily_report',
  'sales_date': 'sales_date',
  'sales_customer_id': 'sales_customer_id',
  'sales_product_id': 'sales_product_id',
  'sales_quantity_sold': 'sales_quantity_sold',
  'sales_unit_price': 'sales_unit_price',
  'sales_sales_amount_(subtotal)': 'sales_sales_amount_(subtotal)',
  'sales_discount_promotion': 'sales_discount_promotion',
  'gross_profit': 'gross_profit',
  'sales_total': 'sales_total',
  'sales_tax': 'sales_tax',
  'sales_payment_method': 'sales_payment_method',
  'sales_responsible': 'sales_responsible',
  'sales_notes': 'sales_notes',
  'purchase_daily_report': 'purchase_daily_report',
  'consignment_daily_report': 'consignment_daily_report',
  'purchase_date': 'purchase_date',
  'purchase_product_id': 'purchase_product_id',
  'purchase_quantity_purchased': 'purchase_quantity_purchased',
  'purchase_purchase_price_(unit)': 'purchase_purchase_price_(unit)',
  'purchase_purchase_amount_(subtotal)': 'purchase_purchase_amount_(subtotal)',
  'purchase_point': 'purchase_point',
  'purchase_supplier': 'purchase_supplier',
  'purchase_category': 'purchase_category',
  'purchase_payment_method': 'purchase_payment_method',
  'purchase_responsible': 'purchase_responsible',
  'purchase_notes': 'purchase_notes',
  'consignment_date': 'consignment_date',
  'consignment_product_id': 'consignment_product_id',
  'consignment_quantity_consigned': 'consignment_quantity_consigned',
  'consignment_consignor': 'consignment_consignor',
  'consignment_contract_price': 'consignment_contract_price',
  'consignment_selling_price': 'consignment_selling_price',
  'consignment_point': 'consignment_point',
  'consignment_sales_status': 'consignment_sales_status',
  'consignment_payment_method': 'consignment_payment_method',
  'consignment_responsible': 'consignment_responsible',
  'consignment_notes': 'consignment_notes',
  'reference_price': 'reference_price',
  'menu_home': 'menu_home',
  'menu_rrs': 'menu_rrs',
  'menu_chat': 'menu_chat',
  'chat_message': 'chat_message',
  'chat_setting': 'chat_setting',
  'menu_marketing': 'menu_marketing',
  'menu_email_template': 'menu_email_template',
  'menu_send_email': 'menu_send_email',
  'menu_discount_configuration': 'menu_discount_configuration',
  'menu_coupon_record': 'menu_coupon_record',
  'menu_cms': 'menu_cms',
  'menu_seo': 'menu_seo',
  'menu_fms': 'menu_fms',
  'menu_fms_account': 'menu_fms_account',
  'out_of_stock_registration': 'out_of_stock_registration',
  'email_template_Description': 'email_template_Description',
  'email_template_Subject': 'email_template_Subject',
  'email_template_Content': 'email_template_Content',
  'email_template_English': 'email_template_English',
  'email_template_Japanese': 'email_template_Japanese',
  'email_template_Simplified Chinese': 'email_template_Simplified Chinese',
  'email_template_Edit_Email_Template': 'email_template_Edit_Email_Template',
  'email_template_English_Subject': 'email_template_English_Subject',
  'email_template_Japanese_Subject': 'email_template_Japanese_Subject',
  'email_template_Simplified_Chinese_Subject': 'email_template_Simplified_Chinese_Subject',
  'email_template_English_content': 'email_template_English_content',
  'email_template_Japanese_content': 'email_template_Japanese_content',
  'email_template_Simplified_Chinese_content': 'email_template_Simplified_Chinese_content',
  'email_template_Traditional_Chinese_Subject': 'email_template_Traditional_Chinese_Subject',
  'email_template_Traditional_Chinese_content': 'email_template_Traditional_Chinese_content',
  'send_email_Recipient': 'send_email_Recipient',
  'send_email_Select_Template': 'send_email_Select_Template',
  'send_email_Select_Language': 'send_email_Select_Language',
  'send_email_Subject': 'send_email_Subject',
  'send_email_Body': 'send_email_Body',
  'send_email_Content': 'send_email_Content',
  'send_email_Preview': 'send_email_Preview',
  'send_email_Send': 'send_email_Send',
  'total_orders': 'total_orders',
  'total_sales_quantity': 'total_sales_quantity',
  'total_order_amount': 'total_order_amount',
  'total_discount_amount': 'total_discount_amount',
  'total_tax_amount': 'total_tax_amount',
  'total_pay_amount_statistics': 'total_pay_amount_statistics',
  'uLsFothaKN': 'uLsFothaKN',
  'VFGniMWIir': 'VFGniMWIir',
  'bVMfWhvydu': 'bVMfWhvydu',
  'id_certificate': 'id_certificate',
  'payment_details': 'payment_details',
  'consignment_total_purchase_quantity': 'consignment_total_purchase_quantity',
  'consignment_total_cost_price': 'consignment_total_cost_price',
  'consignment_total_price': 'consignment_total_price',
  'purchase_total_purchase_quantity': 'purchase_total_purchase_quantity',
  'purchase_total_cost_price': 'purchase_total_cost_price',
  'ad_name': 'ad_name',
  'ad_placement': 'ad_placement',
  'ad_image': 'ad_image',
  'online_offline': 'online_offline',
  'online': 'online',
  'offline': 'offline',
  'start_time': 'start_time',
  'end_time': 'end_time',
  'time': 'time',
  'advertisement_list': 'advertisement_list',
  'pc_home_page_carousel': 'pc_home_page_carousel',
  'app_home_carousel': 'app_home_carousel',
  'advertising_space': 'advertising_space',
  'ad_link': 'ad_link',
  'stock_status_change_note': 'stock_status_change_note',
  'PSmonSgnjX': 'PSmonSgnjX',
  'arvArlFrfX': 'arvArlFrfX',
  'xCWNaTSMxQ': 'xCWNaTSMxQ',
  'vucsrXTzfQ': 'vucsrXTzfQ',
  'EsqMNViBHq': 'EsqMNViBHq',
  'LSyPLaaZjt': 'LSyPLaaZjt',
  'dwawuQNUEi': 'dwawuQNUEi',
  'SYLYoWTeQq': 'SYLYoWTeQq',
  'supports_up_to_n_items': 'supports_up_to_n_items',
  'download_excel': 'download_excel',
  'FOGRMrNbMM': 'FOGRMrNbMM',
  'DXlRCJJEKv': 'DXlRCJJEKv',
  'GbYAnWwRFt': 'GbYAnWwRFt',
  'consignment_to_recycling': 'consignment_to_recycling',
  'Hardware_Derivative_Protective_film': 'Hardware_Derivative_Protective_film',
  'Hardware_Slightly_oxidised': 'Hardware_Slightly_oxidised',
  'Hardware_Oxidised': 'Hardware_Oxidised',
  'Hardware_There_will_be_minor_scratches_due_to_the_unique_design_of_the_product': 'Hardware_There_will_be_minor_scratches_due_to_the_unique_design_of_the_product',
  'Outer_Slight_indentations_from_use': 'Outer_Slight_indentations_from_use',
  'Outer_Normal_indentations_from_use': 'Outer_Normal_indentations_from_use',
  'Outer_Indentations': 'Outer_Indentations',
  'Outer_Color_touch_up': 'Outer_Color_touch_up',
  'Shoulder_Strap_Small_scratches': 'Shoulder_Strap_Small_scratches',
  'Shoulder_Strap_Scratches': 'Shoulder_Strap_Scratches',
  'Shoulder_Strap_Slight_indentations_from_use': 'Shoulder_Strap_Slight_indentations_from_use',
  'Shoulder_Strap_Normal_indentations_from_use': 'Shoulder_Strap_Normal_indentations_from_use',
  'Shoulder_Strap_Indentations': 'Shoulder_Strap_Indentations',
  'Shoulder_Strap_Slight_stains_on_the_handle': 'Shoulder_Strap_Slight_stains_on_the_handle',
  'Shoulder_Strap_Stains_on_the_handle': 'Shoulder_Strap_Stains_on_the_handle',
  'Others_Stains_on_dustbag': 'Others_Stains_on_dustbag',
  'shoulder_strap': 'shoulder_strap',
  'others': 'others',
  'eNxVOVImMN': 'eNxVOVImMN',
  'CxeLXsjeRo': 'CxeLXsjeRo',
  'KaLvBciebv': 'KaLvBciebv',
  'jHkqqeaSOv': 'jHkqqeaSOv',
  'inbox': 'inbox',
  'phone': 'phone',
  'other': 'other',
  'country_code': 'country_code',
  'country': 'country',
  'jpn': 'jpn',
  'city': 'city',
  'write_your_message': 'write_your_message',
  'press_enter_to_send': 'press_enter_to_send',
  'sessions': 'sessions',
  'profile': 'profile',
  'set_profile_with_information': 'set_profile_with_information',
  'greetings': 'greetings',
  'set_a_greeting_message_for_customers_while_starting_a_conversation': 'set_a_greeting_message_for_customers_while_starting_a_conversation',
  'offline_response': 'offline_response',
  'set_auto_reply_pre_settled_messages_while_outside_business_hours': 'set_auto_reply_pre_settled_messages_while_outside_business_hours',
  'business_hours': 'business_hours',
  'set_business_hours': 'set_business_hours',
  'image': 'image',
  'timezone': 'timezone',
  'save': 'save',
  'set_business_hour': 'set_business_hour',
  'at_least_one_business_hour_needs_to_be_set_up': 'at_least_one_business_hour_needs_to_be_set_up',
  'set_holiday': 'set_holiday',
  'any_changes_for_today_will_not_be_possible': 'any_changes_for_today_will_not_be_possible',
  'documents': 'documents',
  'share_files_description': 'share_files_description',
  'photos': 'photos',
  'share_photos_description': 'share_photos_description',
  'upload_new_image': 'upload_new_image',
  'delete_image': 'delete_image',
  'enter_name': 'enter_name',
  'enter_contact_number': 'enter_contact_number',
  'enter_button_content': 'enter_button_content',
  'auto_reply_message': 'auto_reply_message',
  'add_response': 'add_response',
  'add_new_offline_button_and_reply_message': 'add_new_offline_button_and_reply_message',
  'Sent an attachment': 'Sent an attachment',
  'Sent {{count}} attachments': 'Sent {{count}} attachments',
  'at_least_one_business_hour': 'at_least_one_business_hour',
  'add_day_time': 'add_day_time',
  'changes_not_possible_today': 'changes_not_possible_today',
  'add_holiday': 'add_holiday',
  'start_date': 'start_date',
  'end_date': 'end_date',
  'day_of_week': 'day_of_week',
  'customer_details': 'customer_details',
  'no_selected_session': 'no_selected_session',
  'new_tag': 'new_tag',
  'rzdMoKAcEc': 'rzdMoKAcEc',
};

export default LOCALS;
